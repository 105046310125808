import { ref, onMounted, onUnmounted } from 'vue'

export function useNetworkStatus(options = {}) {
  const { enableIntervalCheck = false, intervalTime = 5000 } = options

  const isOnline = ref(navigator.onLine)
  const updateOnlineStatus = () => {
    isOnline.value = navigator.onLine
  }
  let intervalId;

  onMounted(() => {
    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)

    if (enableIntervalCheck) {
      // 启用定时器查询
      intervalId = setInterval(updateOnlineStatus, intervalTime)
    }
    // 初始化状态
    updateOnlineStatus()
  })

  // 清理定时器和事件监听器
  onUnmounted(() => {
    if (enableIntervalCheck || intervalId !== null) {
      clearInterval(intervalId)
      intervalId = null
    }
    window.removeEventListener('online', updateOnlineStatus)
    window.removeEventListener('offline', updateOnlineStatus)
  })
  return {
    isOnline,
    updateOnlineStatus,
  }
}
