<template>
  <div
    v-show="!isOnline && isEmpty"
    class="offline-empty-container"
  >
    <NetworkErrorHintVue
      :language="language"
    />
  </div>
</template>
<script setup>
import { defineProps, defineExpose, defineEmits, watch } from 'vue'
import { useNetworkStatus } from './useNetworkStatus.js'
import NetworkErrorHintVue from './NetworkErrorHint.vue'

defineProps({
  isEmpty: {
    type: Boolean,
    default: false
  },
  language: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(['online-changed'])
const { isOnline } = useNetworkStatus()

watch(() => isOnline.value, (newVal, oldVal) => {
  if(newVal != oldVal) {
    emit('online-changed', newVal)
  }
}, { immediate: true })

defineExpose({
  isOnline
})
</script>
<style lang="less" scoped>
.offline-empty-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
</style>
