<template>
  <div class="error-wrapper">
    <s-empty-state-view
      :content="language.SHEIN_KEY_PWA_31762"
      :button-text="language.SHEIN_KEY_PWA_17185"
      :button-number="1"
      @onButtonClick="handle"
    >
      <template slot="icon">
        <sui_icon_empty_offnet_75px_color size="75px" />
      </template>
    </s-empty-state-view>
  </div>
</template>

<script setup>
import { EmptyStateView as SEmptyStateView } from '@shein/sui-mobile'
import { defineProps } from 'vue'
import { sui_icon_empty_offnet_75px_color } from '@shein-aidc/icon-vue2'

defineProps({
  language: {
    type: Object,
    default: () => {}
  }
})

const handle = ()=> {
  location.reload()
}
</script>

<style lang="less" scoped>
.error-wrapper {
  display: flex;
  flex-direction: column;
  padding: 160/75rem 48/75rem;
  background:  #FFF;
  justify-content: center;
  align-items: center;
}
</style>
